<template>
    <div>
      
     
      <va-card v-if="!loading">
        <div class="text-center">
         <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        </div>
        <va-progress-bar
          :value="bufferValues.value"
          :buffer="bufferValues.buffer"
        >
          Loading
        </va-progress-bar>
      </va-card>
      <div v-if="isModelGenerated">
   
        <question-table v-if="isModelGenerated" :model="this.model"/>

        <!-- start charts -->
        <div class="row"> 

          <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question I: ' + verticalBarChartData[0].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[0]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->


           <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question II: ' + verticalBarChartData[1].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[1]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->


           <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question III: ' + verticalBarChartData[2].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[2]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->

          <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question IV: ' + verticalBarChartData[3].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[3]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->



              <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question V:' + verticalBarChartData[4].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[4]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->


           <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question VI: ' + verticalBarChartData[5].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[5]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->

           
          <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question VIII:' + verticalBarChartData[6].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[6]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->
          

           
          <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question IX: ' + verticalBarChartData[7].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[7]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->
          
           
          <!-- QUESTION CARD  -->
          <div class="flex md3 xs12" >
            <va-card
              :title="'Question X: ' + verticalBarChartData[8].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[8]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->
           
          <!-- QUESTION CARD  -->
          <div class="flex md3 xs12">
            <va-card
              :title="'Question XI: ' + verticalBarChartData[9].datasets[0].description[0]"
            >
              <va-chart
                :data="verticalBarChartData[9]"
                type="vertical-bar"
              />
            </va-card>
          </div>
           <!-- QUESTION CARD  -->
           <!-- QUESTION CARD  -->
          
        </div>
        <!-- end charts -->

       
      </div>
    </div>
</template >
  
<script>
  import QuestionTable from './question-table.vue';
  import firebase from 'firebase';
  export default {
    data () {
      return {
           model: false,
           loading: false,
           AnswersFromUsers: false,
           bufferValues: {
              value: 0,
              buffer: 0,
           },
           uniqueAnswers: [[],[],[],[],[],[],[],[],[],[],[]],

           verticalBarChartData: [
              {
                labels: ['Success'],
                datasets: [
                  {
                    label: 'Total Times Selected',
                    backgroundColor: this.$themes['primary'],
                    borderColor: 'transparent',
                    data: [0],
                  }
                ],
              }
           ]
      };
    },
    async created() {
      this.loading = false
      await this.getModel();
    },
    components: {
      QuestionTable
    },
    computed: {
       isModelGenerated() {
            return this.loading
      },
    },
    methods: {
        ShowDescription(answer) {
          this.$swal({
            title: "Current Question:",
            html: answer,
            type: 'info',
            confirmButtonText: 'Ok'
          })
        },
        async getModel() {

          this.bufferValues.buffer += 60;
          this.bufferValues.value += 30;
       

          //check if parameter :evalID exists in route
          let evaluations;
          let evaluationsData;
         
          if(!this.$route.params.userID){
            //get evalutations after 2022-11-07, that holds the new questions
            evaluations  = await firebase.firestore().collection('evaluations').where('creationDate', '>', new Date('2022-11-07')).get();
            //get data from evaluations collection and save into evaluationsData as object
            evaluationsData = evaluations.docs.map(doc => doc.data());
          }else{
         
            if(this.$route.params.evalID){
              //get evalutations after 2022-11-07, that holds the new questions
              evaluations  = await firebase.firestore().collection('evaluations')
              .where('userID', '==', this.$route.params.userID)
              .where('evalID', '==', this.$route.params.evalID).get();
              //get data from evaluations collection and save into evaluationsData as object
              evaluationsData = evaluations.docs.map(doc => doc.data());
            }else{
            evaluations  = await firebase.firestore().collection('evaluations')
            .where('userID', '==', this.$route.params.userID)
            .get()
            }
            //if not find results for this user, show error message
            if(evaluations.empty){
              this.$swal({
                title: "Error",
                html: "No results found for this user",
                type: 'error',
                confirmButtonText: 'Ok'
              })
              return;
            }
       


            evaluationsData = evaluations.docs.map(doc => doc.data());
            //convert evaluation.creationDate to Date, then remove data that has creation date before 2022-11-07
            evaluationsData = evaluationsData.filter(evaluation => new Date(evaluation.creationDate.toDate()) > new Date('2022-11-07'))

          
            // //get data from evaluations collection and save into evaluationsData as object
            // evaluationsData = evaluations.docs.map(doc => doc.data());
          }



          this.model = evaluationsData;
          let answers = [];
          let QuestionsData = [];
          for (let index = 0; index < 12; index++) {
            QuestionsData[index] = {
                  answersHeader: [],
                  answerLabel: [],
                  answerChoice: [],
            }
          }

          for await (const element of this.model) {
        
            //get user name from user collection
            let user = await firebase.firestore().collection('users').doc(element.userID).get();
            element.displayName = user.data()?.displayName || "No Name";
            //foreach answer in the model append to new array that contains only the answers
            try {
              for  (let index = 0; index < 12; index++) {
                //check if element.answers[index].header is inside array "AllowedAnswers", IF TRUE add answer to array
                if(element.answers[index].header !=  "We welcome your comments for improvement.") {
                  QuestionsData[index].answersHeader.push(element.answers[index].header);
                  QuestionsData[index].answerLabel.push(element.answers[index].answerLabel);
                  QuestionsData[index].answerChoice.push(element.answers[index].answerChoice);
                }else{
                 //remove index from array
                  QuestionsData.splice(index, 1);
                }
              }
            } catch (error) {
              
            }
          };
          //remove the key [9] from QuestionsData array
          QuestionsData.splice(9, 1);
          //unify duplicated keys from QuestionsData[0].answerLabel and save the amount of times it appears in QuestionsData[0].answerChoice
          let unique = [
          [...new Set(QuestionsData[0].answerLabel)],
          [...new Set(QuestionsData[1].answerLabel)],
          [...new Set(QuestionsData[2].answerLabel)],
          [...new Set(QuestionsData[3].answerLabel)],
          [...new Set(QuestionsData[4].answerLabel)],
          [...new Set(QuestionsData[5].answerLabel)],
          [...new Set(QuestionsData[6].answerLabel)],
          [...new Set(QuestionsData[7].answerLabel)],
          [...new Set(QuestionsData[8].answerLabel)],
          [...new Set(QuestionsData[9].answerLabel)],
          ];

         
          await new Promise((resolve,reject) => {
            unique.forEach((element, key, array) => {
              element.forEach((element3) => {
                let count = 0;
                QuestionsData[key].answerLabel.forEach(element4 => {
                  if(element3 == element4) {
                    count++;
                  }
                });
                this.uniqueAnswers[key].push({
                  answersHeader: QuestionsData[key].answersHeader[0],
                  answerLabel: element3,
                  answerChoice: count
                });
              });
              if (key === array.length -1) resolve();
            })
          }).then(async() => {
            //WAIT 2 seconds
            this.bufferValues.buffer += 90;
            this.bufferValues.value += 60;
            await new Promise(resolve => setTimeout(resolve, 1000));

          
            //FOREACH item in uniqueAnswers, add to verticalBarChartData
            this.uniqueAnswers.forEach((element, key) => {
              let labels = [];
              let data = [];
              let backgroundColor = [];
              let description = [];
              element.forEach((element2) => {
                labels.push(element2.answerLabel);
                description.push(element2.answersHeader);
                data.push(element2.answerChoice);
                backgroundColor.push(this.$themes['primary'],);
              });
              this.verticalBarChartData[key] = {
                labels: labels,
                datasets: [
                  {
                    label: 'Total Times Selected',
                    description: description,
                    backgroundColor: backgroundColor,
                    borderColor: 'transparent',
                    data: data,
                  }
                ],
              }
            });
            this.loading = true;
          });
        },
    },
  };
  </script>
  
  <style lang="scss">
  .chart-widget {
    .va-card__body {
      height: 550px;
    }
  }
  </style>
  